import * as React from 'react';
import Footer from '../components/footer';
import Header from '../components/header';


const AboutPage = () => (
  <React.Fragment>
    <Header />

    <div className="container-fluid tan-background">
      <div className="row">
        <div className="col-sm-12 col-md-6 offset-md-3 about-card">
          <h3>About Us</h3>
          <br />
          <p>
            Our business has evolved over the past 40 years from our first retail outlet in Brooklyn to a complete shop at home experience.
            Our clientele receive the utmost personal service and attention.
          </p>
          <p>
            We will assist you in choosing fabrics and expertly design and create treatments that will complement and enhance your windows and decor.
            Additionally, we would be happy to work with interior designers.
          </p>
          <p>
            Avi, the owner, professionally measures and installs every job at no extra cost to you.
            We fabricate everything in our shop and pass the savings on to you!
          </p>
        </div>
      </div>
    </div>

    <div className="container">
      <br />
      <h3>What We Do</h3>
      <br />
      <div className="row">
        <div className="col-12">
          <div className="d-flex align-items-left flex-wrap justify-content-between" style={{whiteSpace: 'nowrap'}}>
            <div className="what-we-do-item">
              <ul className="no-bullets">
                <li>Drapes</li>
                <li>Swags</li>
                <li>Jabots</li>
                <li>Valances</li>
                <li>Roman Shades</li>
              </ul>
            </div>

            <div className="what-we-do-item">
              <ul className="no-bullets">
                <li>Panels</li>
                <li>Cornices</li>
                <li>String Austrian Shades</li>
                <li>Duvet Covers</li>
                <li>Comforters</li>
              </ul>
            </div>

            <div className="what-we-do-item">
              <ul className="no-bullets">
                <li>Bedspreads</li>
                <li>Throw Pillows</li>
                <li>Dust Ruffles</li>
                <li>Shams</li>
                <li>Table Runners</li>
              </ul>
            </div>

            <div className="what-we-do-item">
              <ul className="no-bullets">
                <li>Upholstered Headboards</li>
                <li>Upholstered Benches</li>
                <li>Chaise Lounges</li>
                <li>Window Seats</li>
                <li>Reupholstery</li>
                <li>Custom Table Pads</li>
              </ul>
            </div>

          </div>
        </div>
      </div>
    </div>

    <Footer />

  </React.Fragment>
)

export default AboutPage;